import clsx from 'clsx';
import {Dispatch, SetStateAction} from 'react';
import * as styles from './positionTracker.module.css';

type IPositionTrackerProps<T extends string> = {
    blocks: {
        [key in T]: {
            elementRef: Element | null,
            title: string,
        }
    },
    activeNavLinkId: T,
    setActiveNavLinkId: Dispatch<SetStateAction<T>>,
};

function PositionTracker <T extends string>({
    blocks,
    activeNavLinkId,
    setActiveNavLinkId,
}: IPositionTrackerProps<T>) {
    const scrollToPosition = (id: T) => {
        if (activeNavLinkId === id) {
            return;
        }

        if (blocks[id].elementRef) {
            const element = blocks[id].elementRef;

            if (element) {
                element.scrollIntoView({block: 'center'});
            }
        }
    };

    return (
        <div className={styles.positionTracker}>
            <div className={styles.positionNames}>
                {
                    Object.keys(blocks).map(block => {
                        const blockValue = block as T;
                        const {title} = blocks[blockValue];

                        return (
                            <div
                                key={block}
                                className={clsx(
                                    styles.positionName,
                                    activeNavLinkId === block && styles.active,
                                )}
                                onClick={() => {
                                    scrollToPosition(blockValue);
                                    setActiveNavLinkId(blockValue);
                                }}
                            >
                                {title}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

export default PositionTracker;
