import {useQuery} from '@apollo/client';
import {
    // useRef,
    useState,
    useEffect,
    useMemo,
} from 'react';
import {PageProps} from 'gatsby';
import {GetAttributesDocument} from '../../graphql-types';
import {VideoAttribute, videoAttributes} from '../../interfaces/videoAttributes';
import {SearchInput, Tabs} from '../../components/core';
import Card from '../../components/core/cards/card';
import {Layout} from '../../components/layout';
import StatusColumn from '../../components/core/statusColumn/statusColumn';
import AttributesSection from '../../components/layout/attributesSection/attributesSection';
import AddCategoryModal from '../../modals/categories/addCategory';
import usePageNav from '../../hooks/usePageNav';
import {isInText} from '../../helpers/search';
import {tabsData} from '../../configs/pages/categories/tabs';
import * as styles from './index.module.css';
import toastify, {ToastLocationState} from '../../helpers/toast/toastify';

type VideoAttributeRefs = {
    [key in VideoAttribute]: Element | null
};

type CategoriesPageProps = PageProps<null, null, ToastLocationState>;

const CategoriesPage = ({location}: CategoriesPageProps) => {
    const [activeNavLinkId, setActiveNavLinkId] = useState<VideoAttribute>('theme');

    const [refs, setRefs] = useState<VideoAttributeRefs>({
        type: null,
        category: null,
        theme: null,
        uploadPolicy: null,
        playlistType: null,
    });

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [modal, setModal] = useState<VideoAttribute | undefined>(undefined);
    const {
        data,
        error,
        loading,
    } = useQuery(GetAttributesDocument, {
        fetchPolicy: 'cache-and-network',
    });

    const attributes = useMemo(() => {
        if (!data) {
            return null;
        }

        const attributesData = Object.entries(data);

        return attributesData.map(([key, attributeItems]) => {
            const videoAttributeKey = Object
                .keys(videoAttributes)
                .find(k => videoAttributes[k as VideoAttribute].listQueryType === key) as VideoAttribute | undefined;

            if (!videoAttributeKey) {
                return null;
            }

            return (
                <AttributesSection
                    key={key}
                    category={videoAttributeKey}
                    title={videoAttributes[videoAttributeKey].title}
                    setModal={setModal}
                    ref={element => {
                        setRefs(prevState => ({
                            ...prevState,
                            [videoAttributeKey]: element,
                        }));
                        // refs.current[videoAttributeKey] = element;
                    }}
                >
                    {Array.isArray(attributeItems) && attributeItems.map(attributeItem => (
                        <Card
                            key={attributeItem?.id}
                            title={attributeItem?.name || ''}
                            linkTo={`/categories/${videoAttributeKey}/${attributeItem?.id}`}
                            disabled={!isInText(searchQuery, attributeItem?.name || '')}
                        />
                    ))}
                </AttributesSection>
            );
        });
    }, [data, searchQuery]);

    usePageNav<VideoAttribute>({
        ref: refs.theme,
        navLinkId: 'theme',
        setActiveNavLinkId,
    });
    usePageNav<VideoAttribute>({
        ref: refs.uploadPolicy,
        navLinkId: 'uploadPolicy',
        setActiveNavLinkId,
    });
    usePageNav<VideoAttribute>({
        ref: refs.type,
        navLinkId: 'type',
        setActiveNavLinkId,
    });
    usePageNav<VideoAttribute>({
        ref: refs.category,
        navLinkId: 'category',
        setActiveNavLinkId,
    });
    usePageNav<VideoAttribute>({
        ref: refs.playlistType,
        navLinkId: 'playlistType',
        setActiveNavLinkId,
    });

    useEffect(() => {
        if (!location?.state?.toast) {
            return;
        }

        toastify(location.state.toast);
        window.history.replaceState(null, '');
    }, [location?.state?.toast]);

    return (
        <>
            <Layout title="Category manager">
                <Tabs data={tabsData} />
                {loading && ('Loading data...')}
                {error && !data && (`Error! ${error.message}`)}
                <div className={styles.wrapper}>
                    <div className={styles.search}>
                        <SearchInput
                            query={searchQuery}
                            placeholder="Search parameters"
                            handleChange={setSearchQuery}
                        />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.attributes}>
                            {data && (attributes)}
                        </div>
                        <div className={styles.contentList}>
                            <StatusColumn
                                blocks={{
                                    theme: {
                                        title: videoAttributes.theme.title,
                                        elementRef: refs.theme,
                                    },
                                    category: {
                                        title: videoAttributes.category.title,
                                        elementRef: refs.category,
                                    },
                                    type: {
                                        title: videoAttributes.type.title,
                                        elementRef: refs.type,
                                    },
                                    uploadPolicy: {
                                        title: videoAttributes.uploadPolicy.title,
                                        elementRef: refs.uploadPolicy,
                                    },
                                    playlistType: {
                                        title: videoAttributes.playlistType.title,
                                        elementRef: refs.playlistType,
                                    },
                                }}
                                activeNavLinkId={activeNavLinkId}
                                setActiveNavLinkId={setActiveNavLinkId}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
            {modal && (
                <AddCategoryModal
                    setModal={setModal}
                    category={modal}
                />
            )}
        </>
    );
};

export default CategoriesPage;
