import {
    forwardRef,
    ReactNode,
    Dispatch,
    SetStateAction,
} from 'react';
import {Button} from '../../core';
import {AddIcon} from '../../core/icons';
import {VideoAttribute} from '../../../interfaces/videoAttributes';
import * as styles from './attributesSection.module.css';

interface IAttributeSectionProps {
    category: VideoAttribute,
    title: string,
    children: ReactNode,
    setModal: Dispatch<SetStateAction<VideoAttribute | undefined>>,
}

const AttributesSection = forwardRef<HTMLDivElement, IAttributeSectionProps>(({
    category,
    title,
    setModal,
    children,
}, ref) => (
    <div ref={ref} className={styles.wrapper}>
        <div className={styles.header}>
            <Button
                text="New Item"
                variant="outline"
                size="medium"
                onClick={() => {
                    setModal(category);
                }}
                icon={AddIcon}
            />
            <h3 ref={ref}>{title}</h3>
        </div>
        <div className={styles.content}>
            {children}
        </div>
    </div>
));

export default AttributesSection;
