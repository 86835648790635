import {CategoriesManagerIcon} from '../../../components/core/icons';

// eslint-disable-next-line import/prefer-default-export
export const tabsData = [
    {
        id: 'category-manager',
        title: 'Category manager',
        icon: CategoriesManagerIcon,
        to: '/categories',
    },
];
