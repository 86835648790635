import {useEffect, Dispatch, SetStateAction} from 'react';
import useOnScreen from './useOnScreen';

type UsePageNav<T> = {
    ref: Element | null,
    setActiveNavLinkId: Dispatch<SetStateAction<T>>,
    navLinkId: T,
};

const usePageNav = <T>({
    ref,
    navLinkId,
    setActiveNavLinkId,
}: UsePageNav<T>) => {
    const isOnScreen = useOnScreen(ref);

    useEffect(() => {
        if (isOnScreen) {
            setActiveNavLinkId(navLinkId);
        }
    }, [
        ref,
        isOnScreen,
        setActiveNavLinkId,
        navLinkId,
    ]);

    return ref;
};

export default usePageNav;
