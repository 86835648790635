import {useState, useEffect} from 'react';

const useOnScreen = (ref: Element | null) => {
    const [isOnScreen, setOnScreen] = useState(false);

    useEffect(() => {
        if (!window) {
            return () => false;
        }

        const observer = new IntersectionObserver(
            ([entry]) => setOnScreen(entry.isIntersecting),
            {
                // threshold: [
                //     0,
                //     0.25,
                //     0.5,
                //     0.75,
                //     1,
                // ],
                rootMargin: '-250px 0px -300px 0px',
                threshold: 1,
            },
        );

        if (ref) {
            observer.observe(ref);
        }

        return () => {
            observer.disconnect();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return isOnScreen;
};

export default useOnScreen;
