import {Dispatch, SetStateAction} from 'react';
import PositionTracker from '../scrollTracking/positionTracker';
import * as styles from './statusColumn.module.css';

type StatusColumnProps<T extends string> = {
    blocks: {
        [key in T]: {
            elementRef: Element | null,
            title: string,
        }
    },
    buttons?: any,
    activeNavLinkId: T,
    setActiveNavLinkId: Dispatch<SetStateAction<T>>,
};

function StatusColumn <T extends string>({
    blocks,
    buttons,
    activeNavLinkId,
    setActiveNavLinkId,
}: StatusColumnProps<T>) {
    return (
        <div className={styles.statusColumn}>
            <PositionTracker<T>
                blocks={blocks}
                activeNavLinkId={activeNavLinkId}
                setActiveNavLinkId={setActiveNavLinkId}
            />
            {buttons}
        </div>
    );
}

export default StatusColumn;
